import React from "react";

type ConfiguratorMessage = {
  kind: string;
  data: any;
};

const useListenIframe = () => {
  const [state, setState] = React.useState<
    | {
        STONE_RING: string;
        CASE: string;
        HANDS: string;
        DIAL: string;
        STRAP: string;
        ENGRAVING: string;
        CUFFLINKS: boolean;
      }
    | undefined
  >(undefined);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [iframeHeight, setIframeHeight] = React.useState<number | undefined>(
    undefined
  );
  const closeSubmission = React.useCallback(() => {
    setIsSubmitting(false);
  }, []);

  React.useLayoutEffect(() => {
    window.addEventListener(
      "message",
      (s: MessageEvent<ConfiguratorMessage>) => {
        if (
          "angry.sinoussi.400096.watch.configurator.pub_message" === s.data.kind
        )
          setState(JSON.parse(s.data.data).orderDetails);
        if (
          "angry.sinoussi.400096.watch.configurator.pub_message.SUBMIT" ===
          s.data.kind
        ) {
          setIsSubmitting(true);
        }
        if (
          "angry.sinoussi.400096.watch.configurator.pub_message.HEIGHT" ===
          s.data.kind
        ) {
          console.log("SETTING IFRAME SIZE   ", JSON.parse(s.data.data).height);

          setIframeHeight(JSON.parse(s.data.data).height);
        }
      },
      !1
    );

    return () => {
      window.removeEventListener(
        "message",
        (s: MessageEvent<ConfiguratorMessage>) => {
          if (
            "angry.sinoussi.400096.watch.configurator.pub_message" ===
            s.data.kind
          )
            setState(JSON.parse(s.data.data).orderDetails);
          if (
            "angry.sinoussi.400096.watch.configurator.pub_message.SUBMIT" ===
            s.data.kind
          ) {
            setIsSubmitting(true);
          }
          if (
            "angry.sinoussi.400096.watch.configurator.pub_message.HEIGHT" ===
            s.data.kind
          ) {
            console.log(
              "SETTING IFRAME SIZE   ",
              JSON.parse(s.data.data).height
            );

            setIframeHeight(JSON.parse(s.data.data).height);
          }
        },
        !1
      );
    };
  }, []);

  return { state, closeSubmission, isSubmitting, iframeHeight };
};

export default useListenIframe;
