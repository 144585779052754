import {
  OrderCheckoutState as State,
  OrderCheckoutActions as Action,
  OrderCheckoutActionEnum,
  CheckoutState,
} from "./types";

export const initialState: State = {
  checkoutState: CheckoutState.DESIGN_SUBMISSION,
};

const orderCheckoutReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case OrderCheckoutActionEnum.SET_CHECKOUT_STATE:
      return { ...state, checkoutState: action.payload };

    default:
      return { ...state };
  }
};

export default orderCheckoutReducer;
