import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import ReactDom from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { createNewOrder } from "../../api/orders";
import { get } from "../../constants/theme";
import useWindowSize from "../../hooks/useWindowSize";
import reducer, { initialState } from "../../reducers/orderCheckoutReducer";
import { changeCheckoutState } from "../../reducers/orderCheckoutReducer/action-creators";
import { CheckoutState } from "../../reducers/orderCheckoutReducer/types";
import { OrderSumbmisionState } from "../../types/common/checkout";
import { OrderCheckoutQuery } from "../../types/pages/configurator";
import { AnimatePresence } from "framer-motion";
import Fade from "../Animations/Fade";
import Box from "../Box/Box";
import MotionBox from "../Box/MotionBox";
import { SpecFieldText } from "../Collection/Specification";
import Input from "../Input";
import { FormBtn, Paragraph, Title } from "../Styled/Text";
import { Languages } from "../../types/common/languages";
import { configuratorComponentsTranslation as translations } from "../../constants/configuratorTranlsations";
import PersonalDataConsent from "../PersonalDataConsent";

interface OrderCheckoutForm {
  name: string;
  phone: string;
  email: string;
  personalDataConsent: boolean;
}

type Props = {
  order: {
    STONE_RING: string;
    CASE: string;
    HANDS: string;
    DIAL: string;
    STRAP: string;
    ENGRAVING: string;
    CUFFLINKS: boolean;
  };
  endSubmission: () => void;
  appearAnimationComplete?: (state: boolean) => void;
};

const OrderCheckout = ({
  order,
  endSubmission,
  appearAnimationComplete,
}: Props) => {
  const [orderSubmissionState, setOrderSubmissionState] =
    React.useState<OrderSumbmisionState>({
      isSendingRequest: false,
      isErrorOccur: null,
      isSucceed: false,
    });
  const { height } = useWindowSize();
  const isMenuOpened = true;

  const [{ checkoutState }, dispatch] = React.useReducer(reducer, initialState);

  const { site, translationsJson } = useStaticQuery<OrderCheckoutQuery>(graphql`
    query ConfiguratorOrderCheckoutQuery {
      translationsJson {
        pages {
          configurator {
            modal {
              design_submission {
                title
                orderDetails {
                  STONE_RING
                  CASE
                  DIAL
                  HANDS
                  STRAP
                  ENGRAVING
                  CUFFLINKS
                }
                ENGRAVING_STATUS {
                  yes
                  no
                }
                CUFFLINKS_STATUS {
                  yes
                  no
                }
                button
              }
              user_info_submission {
                title
                inputs {
                  placeholder
                }
                errorMessage
                button
              }
              order_submitted {
                title
                description
                button
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          lang
        }
      }
    }
  `);

  const { design_submission, order_submitted, user_info_submission } =
    translationsJson.pages.configurator.modal;

  const submitDesign = React.useCallback(() => {
    dispatch(changeCheckoutState(CheckoutState.USER_INFO_SUBMISSION));
  }, []);
  const submitOrder = React.useCallback(() => {
    dispatch(changeCheckoutState(CheckoutState.ORDER_SUBMITTED));
  }, []);
  const finishSubmission = React.useCallback(() => {
    appearAnimationComplete && appearAnimationComplete(false);
    endSubmission();
  }, [endSubmission, appearAnimationComplete]);

  const form = useForm<OrderCheckoutForm>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  const submitOrderForm = React.useCallback(
    async (formData: OrderCheckoutForm) => {
      setOrderSubmissionState(() => ({
        isSendingRequest: true,
        isErrorOccur: null,
        isSucceed: false,
      }));
      try {
        await createNewOrder({
          lang: Languages[site.siteMetadata.lang],
          clientData: {
            ...formData,
          },
          orderDetails: {
            stone_ring: order.STONE_RING,
            case: order.CASE,
            dial: order.DIAL,
            hands: order.HANDS,
            strap: order.STRAP,
            engraving: order.ENGRAVING !== "" ? order.ENGRAVING : null,
            cufflinks: order.CUFFLINKS,
          },
        });
        reset();
        setOrderSubmissionState(() => ({
          isSendingRequest: false,
          isErrorOccur: null,
          isSucceed: true,
        }));
        submitOrder();
      } catch (error) {
        setOrderSubmissionState(() => ({
          isSendingRequest: false,
          isErrorOccur: "Unable to create order",
          isSucceed: false,
        }));
        setTimeout(() => {
          if (
            !orderSubmissionState.isSendingRequest ||
            !orderSubmissionState.isSucceed
          )
            setOrderSubmissionState((prev) => ({
              ...prev,
              isErrorOccur: null,
            }));
        }, 3500);
      }
    },
    [order, reset, orderSubmissionState, submitOrder, site.siteMetadata.lang]
  );

  if (typeof document === "undefined") return null;

  return ReactDom.createPortal(
    <>
      <Fade>
        <Box
          sx={{
            zIndex: 300,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: height,
            backgroundColor: get("palette.background.gold"),
            color: get("palette.text.dark_gold"),
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: height,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              top: `${height / 2}px`,
              transform: `translateY(-${height / 2}px)`,
            }}
          >
            {checkoutState === CheckoutState.DESIGN_SUBMISSION && (
              <>
                <Box sx={{ marginBottom: "20px" }}>
                  {design_submission.title.map((t, i) => (
                    <Title
                      key={i}
                      sx={{
                        color: "inherit",
                        textAlign: "center",
                      }}
                      variant="paragraph"
                    >
                      {t}
                    </Title>
                  ))}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {Object.keys(design_submission.orderDetails).map((v) => {
                    if (v === "HANDS") return null;
                    return (
                      <Box
                        sx={{
                          width: ["90%", "65%", "50%", "35%"],
                          display: "flex",
                          flexDirection: ["column", "row", "row", "row"],
                          justifyContent: "space-between",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          lineHeight: "unset",
                          marginBottom: "0.5rem",
                          position: "relative",
                        }}
                        key={v}
                      >
                        <SpecFieldText
                          sx={{
                            height: "100%",
                            width: "fit-content",
                            color: [
                              get("palette.text.brown"),
                              "inherit",
                              "inherit",
                              "inherit",
                            ],
                            textAlign: ["center", "left", "left", "left"],
                            backgroundColor: get("palette.background.gold"),
                            paddingRight: ["0", "0.5rem", "0.5rem", "0.5rem"],
                          }}
                        >
                          {
                            design_submission.orderDetails[
                              v as keyof typeof design_submission.orderDetails
                            ]
                          }
                        </SpecFieldText>
                        <Box
                          sx={{
                            flexGrow: 1,
                            borderBottom: "1px solid",
                            borderBottomColor: get("palette.text.dark_gold"),
                            margin: "5px",
                            alignSelf: "flex-end",
                            display: ["none", "block", "block", "block"],
                          }}
                        />
                        <SpecFieldText
                          sx={{
                            textAlign: ["center", "left", "left", "left"],
                            color: "inherit",
                            whiteSpace: "nowrap",
                            lineHeight: "unset",
                            backgroundColor: get("palette.background.gold"),
                            zIndex: 2,
                            paddingLeft: ["0", "0.5rem", "0.5rem", "0.5rem"],
                            "::after": {
                              display: ["block", "none", "none", "none"],
                              content: `""`,
                              width: "50px",
                              height: "1px",
                              maxHeight: "1px",
                              opacity: 0.85,
                              margin: [
                                "0.25rem auto",
                                "clamp(1.4375rem, -1.1328rem + 12.8516vw, 3.6785rem) auto",
                                "clamp(1.4375rem, -1.1328rem + 12.8516vw, 3.6785rem) auto",
                                "clamp(1.4375rem, -1.1328rem + 12.8516vw, 3.6785rem) auto",
                              ],
                              background: get("palette.text.dark_gold"),
                            },
                          }}
                        >
                          {v === "ENGRAVING"
                            ? order.ENGRAVING.length
                              ? design_submission.ENGRAVING_STATUS.yes
                              : design_submission.ENGRAVING_STATUS.no
                            : null}
                          {v === "CUFFLINKS"
                            ? order.CUFFLINKS
                              ? design_submission.CUFFLINKS_STATUS.yes
                              : design_submission.CUFFLINKS_STATUS.no
                            : null}
                          {!(v === "ENGRAVING" || v === "CUFFLINKS")
                            ? //@ts-ignore
                              translations[site.siteMetadata.lang][v][order[v]]
                            : null}
                        </SpecFieldText>
                      </Box>
                    );
                  })}
                </Box>
                <FormBtn
                  onClick={submitDesign}
                  sx={{
                    textAlign: "center",
                    "&:hover, &:focus": {
                      opacity: 0.85,
                    },
                    width: "auto",
                  }}
                >
                  {`${design_submission.button}`}
                </FormBtn>
              </>
            )}
            {checkoutState === CheckoutState.USER_INFO_SUBMISSION && (
              <>
                <Box
                  sx={{
                    marginBottom: "20px",
                    "& > :nth-child(1n)": {
                      textAlign: "center",
                    },
                  }}
                >
                  {user_info_submission.title.map((t, i) => (
                    <Title
                      key={i}
                      sx={{
                        color: "inherit",
                      }}
                      variant="paragraph"
                    >
                      {t}
                    </Title>
                  ))}
                </Box>

                <FormProvider {...form}>
                  <Box
                    sx={{
                      textAlign: "center",
                      width: ["90vw", "360px"],
                      margin: "0 auto",
                      "& > div > input": {
                        "::placeholder": {
                          color: get("palette.text.gold"),
                          opacity: 0.85,
                        },
                      },
                    }}
                    as="form"
                    onSubmit={handleSubmit(submitOrderForm)}
                  >
                    <Input
                      autofil={{ bg: get("palette.background.gold") }}
                      placeholder={user_info_submission.inputs[0].placeholder}
                      type="text"
                      error={errors.name ? true : false}
                      register={register("name", {
                        required: true,
                        validate: (v) => {
                          return v.trim().length > 0 ? true : "error";
                        },
                      })}
                    />

                    <Input
                      autofil={{ bg: get("palette.background.gold") }}
                      placeholder={user_info_submission.inputs[1].placeholder}
                      type="text"
                      error={errors.phone ? true : false}
                      register={register("phone", {
                        required: true,
                        pattern: /^[^a-zA-Z]+$/g,
                        validate: (v) => {
                          return v.trim().length > 0 ? true : "error";
                        },
                      })}
                    />

                    <Input
                      autofil={{ bg: get("palette.background.gold") }}
                      placeholder={user_info_submission.inputs[2].placeholder}
                      type="text"
                      error={errors.email ? true : false}
                      register={register("email", {
                        required: true,
                        pattern:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />

                    <PersonalDataConsent />

                    <AnimatePresence exitBeforeEnter>
                      {!orderSubmissionState.isErrorOccur ? (
                        <MotionBox
                          key={"default"}
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: 1,
                            transition: { duration: 1, ease: "easeInOut" },
                          }}
                          exit={{
                            opacity: 0,
                            transition: { duration: 0.5, ease: "linear" },
                          }}
                        >
                          <FormBtn
                            sx={{
                              textAlign: "center",
                              "&:hover, &:focus": {
                                opacity: 0.85,
                              },
                            }}
                          >
                            {user_info_submission.button}
                          </FormBtn>
                        </MotionBox>
                      ) : (
                        <MotionBox
                          key={"error"}
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: 1,
                            transition: { duration: 1, ease: "easeInOut" },
                          }}
                          exit={{
                            opacity: 0,
                            transition: { duration: 0.5, ease: "linear" },
                          }}
                        >
                          <FormBtn
                            as="div"
                            sx={{
                              cursor: "default",
                              color: get("palette.error"),
                            }}
                          >
                            {user_info_submission.errorMessage}
                          </FormBtn>
                        </MotionBox>
                      )}
                    </AnimatePresence>
                  </Box>
                </FormProvider>
              </>
            )}
            {checkoutState === CheckoutState.ORDER_SUBMITTED && (
              <>
                {order_submitted.title.map((t, i) => (
                  <Title
                    key={i}
                    sx={{
                      color: "inherit",
                      textAlign: "center",
                    }}
                    variant="paragraph"
                  >
                    {t}
                  </Title>
                ))}
                <Paragraph
                  sx={{
                    textAlign: "center",
                    width: ["100%", "50%", "50%", "35%"],
                    marginTop: "35px",
                    marginBottom: "35px",
                  }}
                >
                  {order_submitted.description}
                </Paragraph>
                <FormBtn
                  onClick={finishSubmission}
                  sx={{
                    "&:hover, &:focus": {
                      opacity: 0.85,
                    },
                    width: "auto",
                  }}
                >
                  {`${order_submitted.button}`}
                </FormBtn>
              </>
            )}
          </Box>

          <MotionBox
            sx={{
              cursor: "pointer",
              position: "absolute",
              left: ["16px", "5%"],
              top: ["45px", "50px"],
              transform: "translateY(-50%)",
              zIndex: 101,
            }}
            onClick={endSubmission}
          >
            <Box
              sx={{
                height: [40, 40, 46, 46],
                width: [40, 40, 46, 46],
                position: "relative",
                "& > :nth-child(1n)": {
                  position: "absolute",
                  height: ["1.5px", "3px", "3px", "3px"],
                  width: "100%",
                  transition: ["background"]
                    .map((prop) => `${prop} 0.5s ease-in-out`)
                    .join(","),
                  background: isMenuOpened
                    ? get("palette.text.dark_gold")
                    : "white",
                },
              }}
            >
              <MotionBox
                animate={{
                  rotate: isMenuOpened ? "45deg" : "180deg",
                  top: isMenuOpened ? "50%" : "35%",
                  translateY: "-50%",
                  transition: {
                    ease: "easeInOut",
                    duration: 0.2,
                  },
                }}
              />
              <MotionBox
                animate={{
                  rotate: isMenuOpened ? "-45deg" : "-180deg",
                  top: isMenuOpened ? "50%" : "65%",
                  translateY: "-50%",
                  transition: {
                    ease: "easeInOut",
                    duration: 0.2,
                  },
                }}
              />
            </Box>
          </MotionBox>
        </Box>
      </Fade>
    </>,
    document.getElementById("portal")!
  );
};

export default React.memo(OrderCheckout);
