export enum CheckoutState {
  DESIGN_SUBMISSION = "DESIGN_SUBMISSION",
  USER_INFO_SUBMISSION = "USER_INFO_SUBMISSION",
  ORDER_SUBMITTED = "ORDER_SUBMITTED",
}

export interface OrderCheckoutState {
  checkoutState: CheckoutState;
}

export enum OrderCheckoutActionEnum {
  SET_CHECKOUT_STATE = "SET_CHECKOUT_STATE",
}

export type SetCheckoutState = {
  type: OrderCheckoutActionEnum.SET_CHECKOUT_STATE;
  payload: CheckoutState;
};

export type OrderCheckoutActions = SetCheckoutState;
