import {
  CheckoutState,
  SetCheckoutState,
  OrderCheckoutActionEnum,
} from "./types";

export const changeCheckoutState = (
  state: CheckoutState
): SetCheckoutState => ({
  type: OrderCheckoutActionEnum.SET_CHECKOUT_STATE,
  payload: state,
});
