import css from "@styled-system/css";
import { motion } from "framer-motion";
import styled from "styled-components";
import { SxProp } from "../../constants/sx";

export const ConfiguratorIframeBox = styled(motion.iframe)<
  SxProp & {
    $iframeHeight?: number | undefined;
  }
>`
  display: block;
  border: none;
  height: 971px;
  width: 100vw;
  margin-bottom: 80px;
  @media (max-width: 720px) {
    height: 1080px;
    ${(props) => props.$iframeHeight && css({ height: props.$iframeHeight })};
  }

  @media (max-width: 420px) {
    height: 780px;
    ${(props) => props.$iframeHeight && css({ height: props.$iframeHeight })};
  }

  ${(props) => props.$iframeHeight && css({ height: props.$iframeHeight })};

  ${(props) => props.sx && css({ ...props.sx })};
`;

export default ConfiguratorIframeBox;
